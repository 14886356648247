import React from "react"
import '../styles/global.css';
import { CrowdCallContainer } from "../components/global/global";
import styled from "@emotion/styled";
import { PatternDiv } from "../components/pattern-div/patternDiv";
import { NavBar } from "../components/navbar/navbar";

const BackgroundPattern = styled(PatternDiv)`
  width: 100vw;
  height: 100vh;
`
const TitleText = styled.h1`
  font-family: 'League Spartan', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 60px;
  text-align: center;
  padding-top: 250px;
`

const SubtitleContainer = styled.div`
  max-width: 600px;
  width: 70%;
  margin: 0 auto;
  text-align: ${props => props.centered ? "center" : "left"};
`

const SubtitleText = styled.p`
  font-size: 20px;
`


export default () => <CrowdCallContainer>
  <BackgroundPattern>
    <NavBar />
    <TitleText>404</TitleText>
    <SubtitleContainer centered={true}>
      <SubtitleText>There is nothing to see here</SubtitleText>
    </SubtitleContainer>
  </BackgroundPattern>
</CrowdCallContainer>
